<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col text-wrapper">
        <span class="text_1">审核中</span>
      </div>
      <div class="box_1">
        <div class="flex-col group_3">
          <img src="../assets/userheadimg.png" class="image_4" />
          <span class="text_2">维保人申请已提交</span>
          <div class="group_4">
            <span class="text_3">
              请您注意短信通知确认审核结果，审核成功后
              <br />
            </span>
            <span class="text_4">可直接进行登录</span>
          </div>
        </div>
        <div class="flex-col items-center button" @click="index()">
          <span>我已知晓</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  name: 'shenhe',
  methods:{
    index(){
      console.log('123');
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped>
@import '../assets/common.css';
.page {
  width: 100%;
}
.header {
  padding-top: 0.063rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  height: 2.81rem;
}
.group_1 {
  flex: 1 1 auto;
  overflow-y: auto;
}
.text_2 {
  margin-top: 1.63rem;
  align-self: center;
  color: rgb(102, 102, 102);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.group_3 {
  width: 16.66rem;
  margin: 2.94rem 0 0 3.31rem;
}
.group_4 {
  margin-top: 0.72rem;
  line-height: 1rem;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  background-color: rgb(237, 237, 237);
}
.box_1 {
  overflow-x: hidden;
  margin-top: -1.5rem;
  background-color: #f5f5f5;
  border-radius: 1.2rem 1.2rem 0 0;
  padding-top: 2.5rem;
}
.button {
  width: 12.5rem;
  margin: 3rem auto 6rem;
  padding: 1.13rem 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  background-color: #005ddb;
  color: #fff;
  background-repeat: no-repeat;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.image_4 {
  align-self: center;
  filter: drop-shadow(0px 0.19rem 0.75rem rgba(0, 0, 0, 0.16));
  width: 5.63rem;
  height: 5.63rem;
}
.text-wrapper {
  padding: 5.88rem 0 6.75rem;
  color: rgb(255, 255, 255);
  font-size: 1.88rem;
  line-height: 1.75rem;
  white-space: nowrap;
  background: #0355d7 url(../assets/bg.png) right no-repeat;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  margin-left: 1.31rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  text-align: left;
  margin-left: 1.31rem;
}
.text_3 {
  color: rgb(102, 102, 102);
  font-size: 0.81rem;
  line-height: 1rem;
}
.text_4 {
  color: rgb(102, 102, 102);
  font-size: 0.81rem;
  line-height: 1rem;
}
.box_1{
  height: 72vh;
}
</style>
